import dynamic from 'next/dynamic'
import css from './SocialLinks.module.scss'
import { pushDataLayerEventHandler } from '@/utils/utils'
const IconoGraphy = dynamic(() => import('../IconoGraphy'))

const SocialLinks = ({ lang, pageType }) => {
  const socialLinksClickHandler = (linkName) => {
    pushDataLayerEventHandler(window, lang, 'social_link_clicked', pageType, {name:linkName})
  }

  return (
    <>
      <div className={css.social}>
        <ul id = 'SocialMediaLinks' className={css.social__list}>
          <li id ='instagramIcon' className={css.social__item}>
            <a
              href='https://instagram.com/wasaltofficial'
              target='_blank'
              title='instagram'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('instagram')
              }}
            >
              <IconoGraphy iconClass={'icon-instagram'} iconColor={'color_gray'}></IconoGraphy>
            </a>
          </li>
          <li id = 'tiktokIcon' className={css.social__item}>
            <a
              href={`${
                lang === 'en' ? 'https://www.tiktok.com/@wasalt?lang=en' : 'https://www.tiktok.com/@wasalt?lang=ar'
              }`}
              target='_blank'
              title='tiktok'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('tiktok')
              }}
            >
              <IconoGraphy iconClass={'icon-tiktok'} iconColor={'color_gray'}></IconoGraphy>
            </a>
          </li>
          <li id = 'facebookIcon' className={css.social__item}>
            <a
              href='https://www.facebook.com/WasaltOfficial'
              target='_blank'
              title='facebook'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('facebook')
              }}
            >
              <IconoGraphy iconClass={'icon-facebook'} iconColor={'color_gray'}></IconoGraphy>
            </a>
          </li>
          <li id = 'linkedInIcon' className={css.social__item}>
            <a
              href='https://www.linkedin.com/company/42315705/admin/'
              target='_blank'
              title='linkedin'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('linkedin')
              }}
            >
              <IconoGraphy iconClass={'icon-linkedin'} iconColor={'color_gray'}></IconoGraphy>
            </a>
          </li>
          <li id = 'youtubeIcon' className={css.social__item}>
            <a
              href='https://www.youtube.com/channel/UCwV9YXTxwCNu-XhmsmWKJFA/featured'
              target='_blank'
              title='youtube'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('youtube')
              }}
            >
              <IconoGraphy iconClass={'icon-youtube'} iconColor={'color_gray'} fontSize={'f_24'}></IconoGraphy>
            </a>
          </li>
          <li id = 'twitterIcon' className={css.social__item}>
            <a
              href='https://twitter.com/WasaltOfficial'
              target='_blank'
              title='twitter'
              className={css.social__link}
              onClick={() => {
                socialLinksClickHandler('twitter')
              }}
            >
              <IconoGraphy iconClass={'icon-twitter'} iconColor={'color_gray'}></IconoGraphy>
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default SocialLinks
